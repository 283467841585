<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogMergeTicket" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '50vw', position: 'relative' }"
      :header="'Merge Tickets'"
      :modal="true"
      :closable="false"
    >
      <Loading
        :active.sync="showLoader"
        :isFullPage="false"
        :zIndex="9000"
      ></Loading>

      <DataTable
        :value="selected"
        responsiveLayout="scroll"
        class="p-datatable-sm"
        :selection.sync="master"
        :sortOrder="1"
        sortField="id"
      >
        <template #header>
          <div class="table-header">
            Selecione o Ticket que irá receber os restantes tickets da listagem
            em baixo.
          </div>
        </template>
        <Column selectionMode="single" :headerStyle="{ width: '3em' }"></Column>
        <Column field="id" header="Id"></Column>
        <Column field="client_email" header="Cliente"></Column>
        <Column field="subject" header="Assunto"></Column>
        <Column field="ticket_status.name" header="Estado"></Column>
        <Column field="ticket_category.name" header="Categoria"></Column>
        <Column
          field="ticket_assigned_user.username"
          header="Téc. Resp."
        ></Column>
      </DataTable>

      <template #footer>
        <Button
          v-if="master"
          :label="'Merge'"
          icon="pi pi-check"
          class="p-button-success"
          @click="merge"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import ticketService from "../../services/tickets.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "MergeTickets",
  components: {
    Loading,
  },
  props: ["showToast", "show", "selected"],
  data() {
    return {
      showLoader: false,
      master: null,
    };
  },

  methods: {
    cancel() {
      this.master = null;
      return this.$emit("cancel");
    },
    merge() {
      let ticketsToImportIds = this.selected.filter((ticket) => {
        if (ticket.id != this.master.id) {
          return ticket.id;
        }
      });
      let ticketsToImport = ticketsToImportIds.map(ticket => ticket.id)
      let message = `Tem a certeza que pretende importar os tickets ${ticketsToImport.join(
        ", "
      )} para o Ticket W4M_${this.master.id}?`;
      message += `\nEste processo é irreversível.`;

      this.$confirm.require({
        key: "dialogMergeTicket",
        header: "Merge Ticket",
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.importTickets(ticketsToImport);
        },
        reject: () => {},
      });
    },
    importTickets(ticketsToImport) {
      this.showLoader = true;
      return ticketService
        .importTickets(this.master.id, { tickets_import: ticketsToImport })
        .then((response) => {
          this.master = null;
          this.showLoader = false;
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro ao fazer merge dos ticket",
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("mergedTickets", {
              status: "error",
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Merge dos Tickets Efetuado com sucesso`,
              detail: ``,
              life: 3000,
            });
          }
          return this.$emit("mergedTickets", {
            status: "ok",
            masterTickedId: response.id,
          });
        });
    },
  },
};
</script>
